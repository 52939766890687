<template>
  <div class="container mescroll-touch">
    <div class="top">
      <img class="menuIcon" src="@/assets/images/menu_comm_icon@2x.png" @click="sidebarShow = true" />
      <div class="searchBox">
        <input class="searchInput" type="text" v-model="Keyword" placeholder="请输入关键字搜索" />
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" @click="search" />
      </div>
    </div>
    <div class="header_title">
      <div class="titleItem titleItem_active">
        <span>活动专区</span>
      </div>
      <div class="titleItem">
        <span @click="$router.push('/resourceInterpret')">活动集锦</span>
      </div>
    </div>
    <div class="content">
      <div class="classify_content">
        <span :class="{'active_classify': activityTypeName == item.name}" v-for="(item, index) in ActivityTypeList" :key="index" @click="selectClassify(item.value, item.name)">{{item.name}}</span>
      </div>
      <!-- <div class="classifyBox">
        <div class="classifyItem" @click="ProvincePopup = true">
          <span class="province">{{ province ? province : "省份" }}</span>
          <van-icon class="classifyIcon" name="arrow-down" />
        </div>
        <div class="classifyItem" @click="ActiveStatePopup = true">
          <span>{{ activeStateName && activeStateName != "全部" ? activeStateName : "活动状态" }}</span>
          <van-icon class="classifyIcon" name="arrow-down" />
        </div>
        <div class="classifyItem" @click="ActivityTypePopup = true">
          <span>{{ activityTypeName && activeStateName != "全部" ? activityTypeName : "活动类型" }}</span>
          <van-icon class="classifyIcon" name="arrow-down" />
        </div>
      </div> -->
      <!-- 省份选择 -->
      <!-- <van-popup v-model="ProvincePopup" round position="bottom">
        <van-area
          title="省份"
          :area-list="areaList"
          :columns-num="1"
          @confirm="areaConfirm"
          @cancel="ProvincePopup = false" />
      </van-popup> -->
      <!-- 活动状态选择 -->
      <van-popup v-model="ActiveStatePopup" round position="bottom">
        <van-picker
          title="活动状态"
          show-toolbar
          value-key="name"
          :columns="ActiveStateList"
          @confirm="ActiveStateConfirm"
          @cancel="ActiveStatePopup = false"
        />
      </van-popup>
      <!-- 活动类型选择 -->
      <van-popup v-model="ActivityTypePopup" round position="bottom">
        <van-picker
          title="活动类型"
          show-toolbar
          value-key="name"
          :columns="ActivityTypeList"
          @confirm="ActivityTypeConfirm"
          @cancel="ActivityTypePopup = false"
        />
      </van-popup>
      <div class="activityListBox">
        <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
          <div class="activityList" id="dataList">
            <div class="activityItem" v-for="(item, index) in list" :key="index" @click="toDetail(item.Id)">
              <div class="cover">
                <img :src="item.KeyImg" >
                <!-- <div class="suspensionBar">
                  <span>{{ item.State }}</span>
                </div> -->
                <div class="liveStatu" v-if="item.Days > 0">距离活动开始还剩{{item.Days}}天</div>
                <div class="liveStatu" v-else>{{timeStatus(item.StartTime, item.EndTime)}}</div>
              </div>
              <div class="title">{{ item.Name }}</div>
              <div class="time" v-if="isSameDay(item.StartTime, item.EndTime)">活动时间：{{item.StartTime.split("T")[0]}}</div>
              <div class="time" v-else>活动时间：{{item.StartTime.split("T")[0]}} ~ {{item.EndTime.split("T")[0]}}</div>
            </div>
          </div>
        </mescroll-vue>
      </div>
    </div>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
// import { areaList } from '@vant/area-data';
import Sidebar from '../../components/sidebar.vue'
import MescrollVue from 'mescroll.js/mescroll.vue'

export default {
  name: 'ActivityZone',
  data() {
    return {
      sidebarShow: false,
      ProvincePopup: false,
      Keyword: '',
      // areaList: areaList,
      province: '',
      ActiveStateList: [{
        value: '',
        name: '全部'
      }, {
        value: 0,
        name: '报名中'
      }, {
        value: 1,
        name: '报名结束'
      }],
      ActiveStatePopup: false,
      activeStateName: '',
      activeState: '',
      ActivityTypeList: [{
        value: 0,
        name: '缝合培训班'
      }, {
        value: 1,
        name: '医生微课'
      },
      {
        value: 2,
        name: '动物实验'
      }],
      ActivityTypePopup: false,
      activityTypeName: '',
      activityType: '',
      list: [],
      PageIndex: 0,
      PageSize: 5,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 5 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        },
			},
    }
  },
  components: {
    Sidebar,
    MescrollVue
  },
  created() {
    this.activityTypeName = this.ActivityTypeList[0].name;
    this.activityType = this.ActivityTypeList[0].value;
  },
  activated(){
    this.mescroll.setBounce(false);
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  methods: {
    search() {
      this.list = [];
      this.mescroll.resetUpScroll();
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    // 获取列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Activity/GetActivityList', {
          Keyword: this.Keyword,
          Region: this.province,
          State: this.activeState,
          Type: this.activityType,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = arr;
          // this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    areaConfirm(val) {
      if(val[0].name != '全部') {
        this.province = val[0].name;
      } else {
        this.province = "";
      }
      this.ProvincePopup = false;
      this.list = [];
      this.mescroll.resetUpScroll();
    },
    ActiveStateConfirm(val) {
      this.activeStateName = val.name;
      this.activeState = val.value;
      this.ActiveStatePopup = false;
      this.list = [];
      this.mescroll.resetUpScroll();
    },
    ActivityTypeConfirm(val) {
      this.activityTypeName = val.name;
      this.activityType = val.value;
      this.ActivityTypePopup = false;
      this.list = [];
      this.mescroll.resetUpScroll();
    },
    selectClassify(val, name) {
      this.activityTypeName = name;
      this.activityType = val;
      this.list = [];
      this.mescroll.resetUpScroll();
    },
    popupClose(val) {
      this.sidebarShow = val;
    },
    toDetail(id) {
      this.$router.push({
        path: 'activityDetail',
        query: {
          id: id
        }
      });
    },
    isSameDay(time1, time2) {
      const date1 = new Date(time1);
      const date2 = new Date(time2);
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },
    timeStatus(startTime, endTime ) {
      const currentTime = new Date();
      const time1 = new Date(startTime);
      const time2 = new Date(endTime);
      if (currentTime < time1) {
        return '';
      } else if (currentTime >= time1 && currentTime <= time2) {
        return '活动中';
      } else {
        return '已结束';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px 0 0;
  box-sizing: border-box;
  background-image: url('../../assets/images/bg_comm_png@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F9F9F9;
  .top {
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin: 0 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .header_title{
    display: flex;
    align-items: center;
    .titleItem {
      position: relative;
      flex: 1;
      height: 40px;
      line-height: 40px;
      position: relative;
      padding: 0 10px;
      font-size: 15px;
      color: #FFFFFF;
      text-align: center;
      // span {
      //   display: inline-block;
      //   width: 100%;
      // }
    }
    .titleItem_active::after {
      content: "";
      position: absolute;
      left: 40%;
      width: 20%;
      height: 3px;
      border-radius: 3px;
      background-color: #FFFFFF;
      bottom: 5px;
    }
  }
  .content {
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
    .classifyBox {
      margin-bottom: 10px;
      display: flex;
      padding: 0 12px;
      .classifyItem {
        display: flex;
        align-items: center;
        font-size: 14px;
        .province {
          display: inline-block;
          max-width: 45px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .classifyIcon {
          font-size: 16px;
          margin-left: 4px;
          margin-top: 2px;
        }
      }
      .classifyItem:not(:last-of-type) {
        margin-right: 15px;
      }
    }
    .classify_content {
      margin: 0 12px 10px;
      span {
        display: inline-block;
        min-width: 60px;
        text-align: center;
        height: 26px;
        line-height: 26px;
        border-radius: 26px;
        border: 1px solid #CCCCCC;
        font-size: 12px;
        color: #666666;
        padding: 0 12px;
        box-sizing: border-box;
      }
      span:not(:last-child) {
        margin-right: 8px;
      }
      .active_classify {
        color: #CA001B;
        border: 1px solid #CA001B;
      }
    }
    .activityListBox {
      flex: 1;
      height: 0;
      .activityList {
        margin: 0 12px;
      }
      .activityItem {
        background-color: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, .08);
        overflow: hidden;
        margin-bottom: 12px;
        .cover {
          position: relative;
          img {
            display: block;
            width: 100%;
          }
          .suspensionBar {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 0 8px;
            height: 28px;
            line-height: 30px;
            text-align: right;
            font-size: 12px;
            color: #FFFFFF;
            background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
          }
          .liveStatu {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 11px;
            color: #CA001B;
            padding: 3px 10px;
            background-color: #F9E5E8;
            border-radius: 0 10px 0 5px;
            .icon_live {
              width: 12px;
              height: 12px;
              margin-left: 6px;
            }
          }
        }
        .title {
          margin: 10px 8px 5px;
          font-size: 14px;
          overflow:hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
        }
        .time {
          margin: 5px 8px 10px;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
}
</style>